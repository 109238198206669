export default {
  tabbar: {
    home: '首頁',
    category:'分類',
    cart:'購物車',
    user:'我的',
    goback:'返回',
    goHome:'返回首頁'
  },
  home:{
    newProductOnline:'新品上線',
    newProduct:'新品',
    hotProduct:'熱賣商品',
    recommendProduct:'爲妳推薦',
    more:'更多'
  },
  cart:{
    emptydescription:'小車大容量，喜歡的都加進來吧',
    delete:'刪除',
    checkAll:'全選',
    goBuy:'去結算',
    totalPrice:'合計'
  },
  my:{
    userID:'用戶ID',
    myOrder:'我的訂單',
    allOrder:'全部訂單',
    unPay:'待支付',
    unDeliver:'待發貨',
    unReceipt:'待收貨',
    unEvaluate:'待評價',
    wallet:'錢包',
    balance:'余額',
    recharge:'充值',
    cashOut:'提現',
    address:'收貨地址',
    customer:'客服',
    addressManage:'地址管理',
    addressEdit:'地址編輯',
    addressAdd:'新增地址',
    signOut:'退出登錄',
    userSetting:'用戶設置',
    avatar:'頭像',
    nickName:'昵稱',
    setNickName:'設置昵稱',
    gender:'性別',
    male:'男',
    female:'女',
    birthday:'生日',
    passwardSet:'密碼設置',
    setPassward:'設置密碼',
    inputSetPassword:'請設置密碼',
    oldPassward:'舊密碼',
    inputOldPassward:'請輸入舊密碼',
    newPassward:'新密碼',
    inputnewPassward:'請輸入新密碼',
    confirmPassward:'確認密碼',
    inputconfirmPassward:'請輸入確認密碼',
    selectBirth:'選擇年月日',
    orderList:'訂單列表',
    orderTab0:'全部',
    orderTab1:'待付款',
    orderTab2:'待發貨',
    orderTab3:'待收貨',
    orderTab4:'已完成',
    orderNo:'訂單編號',
    goEvaluate:'去評價',
    includShippingCost:'含運費',
    freeShippingCost:'免運費',
    cancelOrder:'取消訂單',
    goPay:'去支付',
    refundSale:'退款/售後',
    confirmReceipt:'確認收貨',
    deleteOrder:'刪除訂單',
    orderDetail:'訂單詳情',
    orderSn:'訂單編號',
    orderStatus:'訂單狀態',
    orderTime:'下單時間',
    payType:'支付方式',
    payTime:'支付時間',
    freightPrice:'運    費',
    orderPrice:'訂單金額',
    actualPrice:'實付金額',
    refundAmount:'退款金額',
    leavingMessage:'用戶留言',
    confirmOrder:'確認訂單',
    mallSelf:'商城自營',
    productPrice: '商品金額',
    shippingCost:'運費',
    coupon:'優惠券',
    nouse:'不可用',
    yuan:'元',
    orderRemark:'訂單備注',
    inputOrderRemark:'請輸入備注',
    submitOrder:'提交訂單',
    orderPay:'訂單支付',
    payTip:'請在半小時內完成付款，否則系統自動取消訂單',
    selectPayType:'選擇支付方式',
    paySuccessTip:'我們將盡快爲您配貨~',
    contactKefu:'請聯系客服~',
    lookOrder:'查看訂單',
    paySuccess:'支付成功',
    payFail:'支付失敗',
    rechargeAddress:'充值地址',
    cashoutAddress:'提現地址',
    rechargePrice:'充值金額',
    inputRechargePrice:'請輸入充值金額',
    cashoutPrice:'提現金額',
    inputCashoutPrice:'請輸入提現金額',
    channel:'通道',
    fee:'手續費'
  },
  login:{
    email:'郵箱',
    inputEmail:'請輸入郵箱',
    password:'密碼',
    inputPassword:'請輸入密碼',
    login:'登錄',
    register:'註冊',
    verification:'圖形驗證碼',
    inputVerification:'請輸入圖形驗證碼',
  },
  goods:{
    goodsDetail:'商品詳情',
    goodsParams:'商品參數',
    specifications:'規格',
    params:'參數',
    toAddress:'送至',
    selectedAddress:'選擇收貨地址',
    comment:'評論',
    goodsComment:'商品評論',
    goodComment:'好評率',
    showAllComments:'查看全部評價',
    allComment:'全部',
    goodComment:'好評',
    middleComment:'中評',
    negativeComment:'差評',
    pictureComment:'有圖',
    pleaseSelect:'請選擇',
    pinGou:'拼購',
    allGou:'全額購買',
    selfPrice:'自費金額',
    bugCount:'購買數量',
    noDetail:'無詳情',
    addCart:'加入購物車',
    buyNow:'立即購買',
    noMore:'沒有更多了'
  },
  button:{
    save:'保存',
    delete:'刪除',
    confirm:'確認',
    cancel:'取消',
    submit:'提交'
  },
  operation:{
    success:'操作成功',
    walletRechargeCashOutPriceTip:'金額須大于0',
    uploadRechargeImage:'請上傳充值截圖',
    suceessWaitAudit:'提交成功，等待審核！',
    selfPriceBuyTip:'自費金額需大于0且小于單件總金額',
    confirmPayTip:'是否確認支付?',
    orderingTip:'下單中，請稍後',
    addressChoice:'請選擇地址'
  }
}