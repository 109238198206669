export default {
  tabbar: {
    home: '首页',
    category:'分类',
    cart:'购物车',
    user:'我的',
    goback:'返回',
    goHome:'返回首页'
  },
  home:{
    newProductOnline:'新品上线',
    newProduct:'新品',
    hotProduct:'热卖商品',
    recommendProduct:'为你推荐',
    more:'更多'
  },
  cart:{
    emptydescription:'小车大容量，喜欢的都加进来吧',
    delete:'删除',
    checkAll:'全选',
    goBuy:'去结算',
    totalPrice:'合计'
  },
  my:{
    userID:'用户ID',
    myOrder:'我的订单',
    allOrder:'全部订单',
    unPay:'待支付',
    unDeliver:'待发货',
    unReceipt:'待收货',
    unEvaluate:'待评价',
    wallet:'钱包',
    balance:'余额',
    recharge:'充值',
    cashOut:'提现',
    address:'收货地址',
    customer:'客服',
    addressManage:'地址管理',
    addressEdit:'地址编辑',
    addressAdd:'新增地址',
    signOut:'退出登录',
    userSetting:'用户设置',
    avatar:'头像',
    nickName:'昵称',
    setNickName:'设置昵称',
    gender:'性别',
    male:'男',
    female:'女',
    birthday:'生日',
    passwardSet:'密码设置',
    setPassward:'设置密码',
    inputSetPassword:'请设置密码',
    oldPassward:'旧密码',
    inputOldPassward:'请输入旧密码',
    newPassward:'新密码',
    inputnewPassward:'请输入新密码',
    confirmPassward:'确认密码',
    inputconfirmPassward:'请输入确认密码',
    selectBirth:'选择年月日',
    orderList:'订单列表',
    orderTab0:'全部',
    orderTab1:'待付款',
    orderTab2:'待发货',
    orderTab3:'待收货',
    orderTab4:'已完成',
    orderNo:'订单编号',
    goEvaluate:'去评价',
    includShippingCost:'含运费',
    freeShippingCost:'免运费',
    cancelOrder:'取消订单',
    goPay:'去支付',
    refundSale:'退款/售后',
    confirmReceipt:'确认收货',
    deleteOrder:'删除订单',
    orderDetail:'订单详情',
    orderSn:'订单编号',
    orderStatus:'订单状态',
    orderTime:'下单时间',
    payType:'支付方式',
    payTime:'支付时间',
    freightPrice:'运    费',
    orderPrice:'订单金额',
    actualPrice:'实付金额',
    refundAmount:'退款金额',
    leavingMessage:'用户留言',
    confirmOrder:'确认订单',
    mallSelf:'商城自营',
    productPrice: '商品金额',
    shippingCost:'运费',
    coupon:'优惠券',
    nouse:'不可用',
    yuan:'元',
    orderRemark:'订单备注',
    inputOrderRemark:'请输入备注',
    submitOrder:'提交订单',
    orderPay:'订单支付',
    payTip:'请在半小时内完成付款，否则系统自动取消订单',
    selectPayType:'选择支付方式',
    paySuccessTip:'我们将尽快为您配货~',
    contactKefu:'请联系客服~',
    lookOrder:'查看订单',
    paySuccess:'支付成功',
    payFail:'支付失败',
    rechargeAddress:'充值地址',
    cashoutAddress:'提现地址',
    rechargePrice:'充值金额',
    inputRechargePrice:'请输入充值金额',
    cashoutPrice:'提现金额',
    inputCashoutPrice:'请输入提现金额',
    channel:'通道',
    fee:'手续费'
  },
  login:{
    email:'邮箱',
    inputEmail:'请输入邮箱',
    password:'密码',
    inputPassword:'请输入密码',
    login:'登录',
    register:'注册',
    verification:'图形验证码',
    inputVerification:'请输入图形验证码',
  },
  goods:{
    goodsDetail:'商品详情',
    goodsParams:'商品参数',
    specifications:'规格',
    params:'参数',
    toAddress:'送至',
    selectedAddress:'选择收货地址',
    comment:'评论',
    goodsComment:'商品评论',
    goodComment:'好评率',
    showAllComments:'查看全部评价',
    allComment:'全部',
    goodComment:'好评',
    middleComment:'中评',
    negativeComment:'差评',
    pictureComment:'有图',
    pleaseSelect:'请选择',
    pinGou:'拼购',
    allGou:'全额购买',
    selfPrice:'自费金额',
    bugCount:'购买数量',
    noDetail:'无详情',
    addCart:'加入购物车',
    buyNow:'立即购买',
    noMore:'没有更多了'
  },
  button:{
    save:'保存',
    delete:'删除',
    confirm:'确认',
    cancel:'取消',
    submit:'提交'
  },
  operation:{
    success:'操作成功',
    walletRechargeCashOutPriceTip:'金额须大于0',
    uploadRechargeImage:'请上传充值截图',
    suceessWaitAudit:'提交成功，等待审核！',
    selfPriceBuyTip:'自费金额需大于0且小于单件总金额',
    confirmPayTip:'是否确认支付?',
    orderingTip:'下单中，请稍后',
    addressChoice:'请选择地址'
  }
}
