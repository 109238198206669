export default {
  tabbar: {
    home: 'Home',
    category:'Category',
    cart:'Cart',
    user:'My',
    goback:'BACK',
    goHome:'home page'
  },
  home:{
    newProductOnline:'new',
    newProduct:'new',
    hotProduct:'hot',
    recommendProduct:'recommend',
    more:'more'
  },
  cart:{
    emptydescription:'The car has a large capacity, please add whatever you like!',
    delete:'delete',
    checkAll:'check all',
    goBuy:'buy',
    totalPrice:'total price'
  },
  my:{
    userID:'ID',
    myOrder:'MY ORDERS',
    allOrder:'All orders',
    unPay:'UNPAID',
    unDeliver:'UNSHIPPED',
    unReceipt:'UNRECEIVED',
    unEvaluate:'UNEVALUATE',
    wallet:'WALLET',
    balance:'Balance',
    recharge:'RECHARTE',
    cashOut:'CASH OUT',
    address:'ADDRESS',
    customer:'CUSTOMER',
    addressManage:'manage address',
    addressEdit:'edit address',
    addressAdd:'add address',
    signOut:'SIGN OUT',
    userSetting:'user setting',
    avatar:'avatar',
    nickName:'nickname',
    setNickName:'set nickname',
    gender:'gender',
    male:'male',
    female:'female',
    birthday:'birthday',
    passwardSet:'passward',
    setPassward:'passward',
    inputSetPassword:'Please set the passward',
    oldPassward:'old passward',
    inputOldPassward:'Please enter old password',
    newPassward:'new passward',
    inputnewPassward:'Please enter new password',
    confirmPassward:'confirmation passward',
    inputconfirmPassward:'Please enter the confirmation password',
    selectBirth:'select birthday',
    orderList:'order list',
    orderTab0:'ALL',
    orderTab1:'UNPAID',
    orderTab2:'UNSHIPPED',
    orderTab3:'NOT RECEIVED',
    orderTab4:'COMPLETED',
    orderNo:'Order No',
    goEvaluate:'Go evaluate',
    includShippingCost:'Including shipping cost',
    freeShippingCost:'Free',
    cancelOrder:'cancel',
    goPay:'Go pay',
    refundSale:'refund',
    confirmReceipt:'Confirm',
    deleteOrder:'delete',
    orderDetail:'order detail',
    orderSn:'order no',
    orderStatus:'order status',
    orderTime:'order time',
    payType:'pay type',
    payTime:'pay time',
    freightPrice:'freight',
    orderPrice:'Order amount',
    actualPrice:'Actual payment amount',
    refundAmount:'Refund amount',
    leavingMessage:'guest book',
    confirmOrder:'Confirm order',
    mallSelf:'Self operated mall',
    productPrice: 'Product amount',
    shippingCost:'freight',
    coupon:'coupon',
    nouse:'Not available',
    yuan:'RMB',
    orderRemark:'remark',
    inputOrderRemark:'Please enter a note',
    submitOrder:'place order',
    orderPay:'Order Pay',
    payTip:'Please complete the payment within half an hour, otherwise the system will automatically cancel the order',
    selectPayType:'Choose payment method',
    paySuccessTip:'We will arrange the shipment for you as soon as possible~',
    contactKefu:'Please contact customer service~',
    lookOrder:'View orders',
    paySuccess:'Payment successful',
    payFail:'Payment failed',
    rechargeAddress:'Recharge address',
    cashoutAddress:'Cash out address',
    rechargePrice:'Recharge price',
    inputRechargePrice:'Please enter the recharge amount',
    cashoutPrice:'Cash out price',
    inputCashoutPrice:'Please enter the withdrawal amount',
    channel:'Channel',
    fee:'Service Charge'
  },
  login:{
    email:'email',
    inputEmail:'Please enter the email',
    password:'password',
    inputPassword:'Please enter the mobile',
    login:'LOGIN',
    register:'REGISTER',
    verification:'verification code',
    inputVerification:'Please enter the verification code',
  },
  goods:{
    goodsDetail:'Goods Details',
    goodsParams:'Goods Params',
    specifications:'Specifications',
    params:'params',
    toAddress:'to',
    selectedAddress:'Select shipping address',
    comment:'comment',
    goodsComment:'Goods comments',
    goodComment:'Positive review rate',
    showAllComments:'View all reviews',
    allComment:'ALL',
    goodComment:'GOOD',
    middleComment:'MIDDLE',
    negativeComment:'BAD',
    pictureComment:'PICTURE',
    pleaseSelect:'select',
    pinGou:'Partial purchase',
    allGou:'Full purchase',
    selfPrice:'Self paid amount',
    bugCount:'Purchase quantity',
    noDetail:'no detail',
    addCart:'add to cart',
    buyNow:'buy now',
    noMore:'There is nothing more'
  },
  button:{
    save:'SAVE',
    delete:'DELETE',
    confirm:'CONFIRM',
    cancel:'CANCEL',
    submit:'SUBMIT'
  },
  operation:{
    success:'Operation successful',
    walletRechargeCashOutPriceTip:'The amount must be greater than 0!',
    uploadRechargeImage:'Please upload a screenshot of the recharge!',
    suceessWaitAudit:'Submitted successfully, waiting for review!',
    selfPriceBuyTip:'The self paid amount must be greater than 0 and less than the total amount of a single item!',
    confirmPayTip:'Confirm Payment?',
    orderingTip:'Ordering in progress, please wait a moment',
    addressChoice:'Please select an address'
  }
}
